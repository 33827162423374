<script>
	import Vue from 'vue';
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../helper/Gen';

	export default {
		extends: BaseVue,
		data() {
			return {
				data:[],
				dataPage:[],
				filter:{sort:'favorite'},
				hero:{},
				product:[]
            }
		},
		async mounted() {
			await this.ready();
			if (typeof SEMICOLON != "undefined") SEMICOLON.documentOnReady.init()
			setTimeout(() => {
				$('[data-toggle="tooltip"]').tooltip();
				SEMICOLON.documentOnReady.init()
				SEMICOLON.slider.sliderParallax()
				SEMICOLON.widget.carousel()
			}, 300)
            this.$set(this.$root, 'page', this);
            this.apiGetStaticHero()
            this.getDataFavorite()
		},
        methods: {
        	async getStaticHero(id){
	            return (await Gen.apirest('/static-hero', {id:id}, ()=>{})).data
	        },
	        async apiGetStaticHero(){
	            this.hero = await this.getStaticHero(5)
	        },
	        getDataFavorite(){
	        	Gen.apirest("/favorite-product",Gen.clone(this.$route.query),(err,resp)=>{
                    if(err) console.log(err)
                    this.product = resp.data
                    setTimeout(()=>{
                    	SEMICOLON.documentOnLoad.init()
                    },300)
                })
	        },
	        onPaging(page = 1){
				if($(".page-item.active").text() == page) return 
				var query = Object.assign(Gen.clone(this.$route.query),{
					page:page
				})
				this.$router.push({
					name:this.$route.name,
					query:query
				})
			},
			clickFilter(){
				$('#filterMobile').addClass('filter-show')
			},
			closeFilter(){
				$('#filterMobile').removeClass('filter-show')
			}
        },
        watch:{
        	'$route.query'(v){
				this.getDataFavorite()
			},
        	'filter.sort'(v){
				this.$router.push({
                    path: this.$route.path, 
                    query:{sort:v}
                })
			},
        }
	};
</script>
<template>
	<div>
	<section id="slider" class="slider-element boxed-slider notoppadding">
	    <div class="clearfix">
	        <div class="fslider" data-touch="false" data-easing="easeInQuad">
	            <div class="flexslider">
	                <div class="slider-wrap">
						<div v-if="!isMobile" class="slide" :data-thumb="uploader(hero.img_desktop)">
							<img :src="uploader(hero.img_desktop)" :alt="hero.title" :title="hero.title">
							<div class="flex-caption slider-caption-bg">
								<h2 :style="'color:'+hero.ash_title_color+';font-family:'+hero.ash_title_font_type+';font-size:'+hero.ash_title_size+'px;'">{{ hero.title }}</h2>
								<p :style="'font-size:'+hero.ash_sub_title_size+'px;color:'+hero.ash_sub_title_color+';font-family:'+hero.ash_sub_title_font_type+';'" v-if="hero.sub_title">{{hero.sub_title}}</p>
							</div>
						</div>
						<div v-else class="slide" :data-thumb="uploader(hero.img_mobile)">
							<img :src="uploader(hero.img_mobile)" :alt="hero.title" :title="hero.title">
							<div class="flex-caption slider-caption-bg">
								<h2 :style="'color:'+hero.ash_title_color+';font-family:'+hero.ash_title_font_type+';'">{{ hero.title }}</h2>
							</div>
						</div>
					</div>
	            </div>
	        </div>
	    </div>
	</section>
	<section id="content" style="background-color: #000; overflow: visible">
	    <div class="content-wrap pt-0 pb-0">
	        <div id="section-about" class="section page-section bg_white nobottommargin notopmargin clearfix">
	            <div class="container clearfix">
	                <div class="row justify-content-around">
	                    <div class="col-md-7">
	                        <ol class="breadcrumb">
	                            <li class="breadcrumb-item"><router-link :to="{name:'Home'}">{{web.mn_home}}</router-link></li>
	                            <li class="breadcrumb-item active" aria-current="page">{{web.mn_customer_favourite}}</li>
	                        </ol>
	                    </div>
	                    <div class="col-md-3 d-none d-md-block">
	                        <!-- <div class="txt_sort_by">
	                            <p>{{web.lbl_sort_by}}</p>
	                        </div> -->
	                    </div>
	                    <div class="col-md-2 pl-md-0 d-none d-md-block">
	                        <!-- <div class="form-group select_custom_dark">
	                            <select id="template-contactform-service" v-model="filter.sort" name="template-contactform-service"
	                                class="sm-form-control frm_custom_filter valid">
	                                <option value="favorite">{{web.lbl_most_favourite}}</option>
	                                <option value="review">{{web.lbl_most_reviewed}}</option>
	                            </select>
	                        </div> -->
	                    </div>
	                </div>
	                <div class="row">
	                    
	                    <div class="col-md-12">
	                        <!-- <div class="floating-button d-md-none mb-3">
	                            <a href="javascript:;" @click="clickFilter()" class="btn_line mt-0 toggleFilter">{{web.lbl_filter}}</a>
	                        </div> -->
	                        <div class="row tight">
	                            <div class="row tight" v-if="product.length">
	                                <div class="col-md-4 col-lg-3 col-6" v-for="(v,k) in product" :key="k">
	                                    <div class="wrap_prod_list">
	                                        <div class="wrap_prod_thumb">
	                                            <router-link :to="{name:'DetailProduct',params:{slug:v.apd_slug}}">
	                                            	<img :src="uploader(v.apd_image)" :alt="v.apd_name" :title="v.apd_name" :mobile-img-src="uploader(v.apd_image)"/>
	                                            </router-link>
	                                            <a href="javascript:;" :id="'fav_'+v.apd_id" @click="addFavorite(v)" class="btn_fav">
	                                            	<i v-if="v.isFavorite=='Y'" class="icon-heart3"></i>
	                                            	<i v-else class="icon-line-heart"></i>
	                                            </a>
	                                        </div>
	                                        <div class="prod_desc">
	                                            <h3><router-link :to="{name:'DetailProduct',params:{slug:v.apd_slug}}">{{v.apd_name}}</router-link></h3>
	                                            <h4>{{v.apd_year}}</h4>
	                                            <p>Rp {{v.price}}</p>
	                                            <div class="rating_prod">
	                                                <i :class="v.apd_review_start>=1 ? 'icon-line-star checked_rat' : 'icon-line-star' "></i>
		                                            <i :class="v.apd_review_start>1 ? 'icon-line-star checked_rat' : 'icon-line-star' "></i>
		                                            <i :class="v.apd_review_start>2 ? (v.apd_review_start > 2 && v.apd_review_start < 3 ? 'icon-line-star checked_rat half' : 'icon-line-star checked_rat')  : 'icon-line-star' "></i>
		                                            <i :class="v.apd_review_start>3 ? (v.apd_review_start > 3 && v.apd_review_start < 4 ? 'icon-line-star checked_rat half' : 'icon-line-star checked_rat') : 'icon-line-star' "></i>
		                                            <i :class="v.apd_review_start>4 ? (v.apd_review_start > 4 && v.apd_review_start < 5 ? 'icon-line-star checked_rat half' : 'icon-line-star checked_rat') : 'icon-line-star' "></i>
	                                            </div>
	                                        </div>
	                                    </div>
	                                </div>
	                                <div class="clearfix"></div>
	                            </div>
	                            <div class="row tight" v-else>
	                            	<div class="col-12 text-center">
	                            		<h4>{{web.lbl_no_product}}</h4>
	                            	</div>
	                            </div>
	                        </div>
	                    </div>
	                </div>
	            </div>
	        </div>
	    </div>
	    
	    
	    <div class="clear"></div>
	    <!-- <div id="filterMobile">
	        <div class="text-left filter-close">
	            <i class="icon-line-cross icon-lg toggleFilter"></i>
	        </div>
	        <div>
	            <div class="txt_sort_by">
	                <p>{{web.lbl_sort_by}}</p>
	            </div>
	        </div>
	        <div>
	            <div class="form-group select_custom_dark mb-3">
                    <select id="template-contactform-service" v-model="filter.sort" name="template-contactform-service"
                        class="sm-form-control frm_custom_filter valid">
                        <option value="favorite">{{web.lbl_most_favourite}}</option>
                        <option value="review">{{web.lbl_most_reviewed}}</option>
                    </select>
                </div>
	        </div>
	        <a href="javascript:;" @click="closeFilter()" class="btn_line d-block text-center toggleFilter">{{web.btn_apply_filter}}</a>
	    </div> -->
	</section>
	</div>
</template>